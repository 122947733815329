@import 'sass/colors';

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html, body {
    height: 100%;
    color: $tertiary-text-color;
}

a {
    text-decoration: none;
    color: $tertiary-text-color;
}

body  {
    font-family: ProximaNova;
    margin: 0;
    padding: 0;
    overflow: auto;
    print-color-adjust: exact !important;
    -webkit-overflow-scrolling: touch;
    -webkit-print-color-adjust: exact !important;
}

#app-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

img {
    user-select: none;
}

svg {
    display: block;
}

button, textarea, input {
    font-family: ProximaNova;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.uf-button.uf-button-primary {
    cursor: pointer;
    background: $default-primary !important;
    border-color: $default-primary !important;
    color: $primary-blank-color !important;
}

#beamerLastPostTitle {
    position: fixed !important;
}

.main-tooltip {
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    padding: 0.25rem 0.5rem !important;
    background-color: $tertiary-text-color !important;
    color: $primary-blank-color !important;
    z-index: 15 !important;
    white-space: pre-wrap !important;
    text-align: center !important;
    opacity: 1 !important;
}