.app-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 4s infinite;

    img {
        max-width: 11.25rem;
        max-height: 11.25rem;
    }

    &--child {
        position: initial;
        transform: none;
        padding: 1rem;
    }
}

@keyframes pulse {
    0%, 100% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}