@import 'sass/colors';
@import "sass/breakpoints";

header {
    position: relative;
    border-bottom: 0.0625rem solid #E1E8EF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 0 0.5rem;

    height: 4.5rem;

    background-color: $primary-blank-color;
    

    z-index: 1;

    @include medium {
        padding: 0 1.5rem;
    }

    @media print {
        display: none;
    }
}