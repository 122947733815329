@import "sass/breakpoints.scss";

.component {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.neatroLogo {
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    @include medium {
        flex-direction: row;
        padding: 3rem 2rem;
    }

    @include large {
        padding: 5rem 2rem;
    }
}

.errorTextSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.errorTitle {
    font-size: 2.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.errorText {
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
}

.illustration {
    width: 100%;
    max-width: 30rem;

    @include medium {
        max-width: 25rem;
    }

    @include large {
        max-width: 30rem;
    }
}

.refreshButton {
    margin-bottom: 2.5rem;
}