@import 'sass/colors';

.toastify-toast {
    .Toastify__toast {
        border-radius: 0.25rem;
    }

    .Toastify__toast-body, .Toastify__close-button {
        color: $tertiary-text-color;
    }

    .Toastify__toast--success {
        background: $primary-success-background-color;
    }

    .Toastify__toast--warning {
        background: $primary-warning-background-color;
    }

    .Toastify__toast--error {
        background: $primary-error-background-color;
    }

    .Toastify__progress-bar--success {
        background: $tertiary-success-color;
    }

    .Toastify__progress-bar--error {
        background: $primary-error;
    }
}