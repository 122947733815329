$x-small: 425px;
$small: 600px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1440px;
$wide: 1920px;

@mixin x-small {
    @media screen and (min-width: $x-small){
        @content;
    }
}

@mixin small {
    @media screen and (min-width: $small){
        @content;
    }
}

@mixin medium {
    @media screen and (min-width: $medium){
        @content;
    }
}

@mixin large {
    @media screen and (min-width: $large){
        @content;
    }
}

@mixin x-large {
    @media screen and (min-width: $x-large){
        @content;
    }
}

@mixin xx-large {
    @media screen and (min-width: $xx-large){
        @content;
    }
}

@mixin wide {
    @media screen and (min-width: $wide){
        @content;
    }
}