.component {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border: none;
    outline: 0;
    cursor: pointer;
    white-space: nowrap;
    background: none;

    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    border-radius: 1.25rem;
    font-weight: 600;

    &Small {
        padding: 0.25rem 1.25rem;
        font-size: 0.875rem;
        border-radius: 1rem;
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &Loading {
        padding: 0 1.375rem;    
        height: 2.5rem;
        min-width: 5.5rem;
        
        svg {
            width: 1.5rem;
        }
    }

    &LoadingSmall {
        height: 1.85rem;
    }

    &NoPadding {
        padding: 0;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}