@import 'sass/breakpoints';
@import 'sass/colors';

.component {
    display: none;
    position: fixed;
    z-index: 6;

    right: 1.25rem;
    bottom: 1.25rem;
    padding: 0.5rem;
    
    opacity: 1 !important;
    background-color: $primary-blank-color;
    border-radius: 50%;

    filter: drop-shadow(0 0.375rem 0.375rem rgba(0, 0, 0, 0.12)) drop-shadow(0 0.25rem 1rem rgba(0, 0, 0, 0.10));

    @include medium {
        display: block;
    }

    &Hidden {
        display: none;
    }
}
    
.icon {
    width: 1.5rem;
    height: 1.5rem;
}