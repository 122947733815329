@font-face {
    font-family:"ProximaNova";
    src: url("./proxima-nova/ProximaNova-Regular.ttf");
    font-display:swap;
    font-style:normal;
    font-weight:normal;
    font-stretch:normal;
}

@font-face {
    font-family:"ProximaNova";
    src: url("./proxima-nova/ProximaNova-Semibold.ttf");
    font-display:swap;
    font-style:normal;
    font-weight:600;
    font-stretch:normal;
}

@font-face {
    font-family:"ProximaNova";
    src: url("./proxima-nova/ProximaNova-RegularIt.ttf");
    font-display:swap;
    font-style:italic;
    font-weight:normal;
    font-stretch:normal;
}

@font-face {
    font-family:"ProximaNova";
    src: url("./proxima-nova/ProximaNova-SemiboldIt.ttf");
    font-display:swap;
    font-style:italic;
    font-weight:600;
    font-stretch:normal;
}

@font-face {
    font-family:"ProximaNova";
    src: url("./proxima-nova/ProximaNova-Bold.ttf");
    font-display:swap;
    font-style:normal;
    font-weight:700;
    font-stretch:normal;
}

@font-face {
    font-family: "IndieFlower";
    src: url("./IndieFlower-Regular.ttf");
    font-display: swap;
}