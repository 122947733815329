$default-primary: #566CFF;
$default-secondary: #3198FF;

$default-primary-darker: #445AEE;

$default-logo-color: #3A42CE;

$box-shadow-primary: #1A4FDE;
$box-shadow-secondary: #14579A;

$primary-background-color: #FCF6E6;
$secondary-background-color: #2B4054;
$tertiary-background-color: #D0E8FF;
$quadrary-background-color: #DCEBFA;
$quinary-background-color: #F8ECCC;
$senary-background-color: #ECEFF2;

$primary-selected-background: #EAEDFF;

$box-shadow-primary-16: rgba($box-shadow-primary, 0.16);
$box-shadow-primary-24: rgba($box-shadow-primary, 0.24);

$box-shadow-secondary-08: rgba($box-shadow-secondary, 0.08);
$box-shadow-secondary-24: rgba($box-shadow-secondary, 0.24);

$primary-text-color: #656472;
$secondary-text-color: #9A99B1;
$tertiary-text-color: #292833;
$quadrary-text-color: #D8D8D8;

$banner-image-background-color: #B6D8E3;
$neatro-image-background-color: #FAF0E7;

$delete-button-color: black;
$primary-success-color: #38955D;
$secondary-success-color: #7BB97A;
$tertiary-success-color: #26C484;
$quadrary-success-color: #DBF1E8;
$quinary-success-color: #E3F4BD;
$senary-success-color: #65B997;
$septary-success-color: #D2DECB;

$primary-blank-color: #FFF;
$secondary-blank-color: #F4F8FC;
$tertiary-blank-color: #D7E2EE;
$quadrary-blank-color: #E9F5F0;

$primary-empty-color: #E1E8EF;

$disabled-text-color: #5A5C5E;

$primary-border-color: #C2CBD5;
$secondary-border-color: #E8EDF2;
$tertiary-border-color: rgba($secondary-text-color, 0.32);
$quadrary-border-color: #C2D5E8;

$primary-border-input-color: #C5CACF;

$primary-error: #EF6060;
$primary-warning: #F6E8B4;
$primary-success-background-color: #F8FFFC;
$primary-warning-background-color: #FFC37C;
$primary-error-background-color: #FFF2F2;
$secondary-error-background-color: #FFE3E3;
$tertiary-error-background-color: #FBE7E7;

$radar-tile-background-color: #FFFAEB;
$google-blue: #4285F4;

$save-background-color: #D2E8DF;
$save-font-color: #197E55;

$very-high-scale-color: #F3FFF6;
$high-scale-color: #FAFFF7;
$medium-scale-color: #FFF8EF;
$low-scale-color: #FFE7E7;

$primary-gauge-background-color: #EFE4CD;
$primary-gauge-active-background-color: #FFC440;
$primary-gauge-active-border-color: #DCA319;

$action-item-empty-color: #FFF9E5;
$linked-comments-empty-state-color: #E5F1FF;

$retro-hint-color: #DFE7C5;
$icebreaker: #FFE2D9;

$dark-mode-color-1: #3D3D3D;
$dark-mode-color-2: #2D2D2D;
$dark-active-color: #98A5FF;

$infos-zone-color: #E4E5DE;

$neatroverse-bg-color: #F5F2EF;

