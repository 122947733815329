@import 'sass/colors';

.full-button {
    color: $primary-blank-color;
    fill: $primary-blank-color;
    background-color: $default-primary;

    &:hover:not(:disabled) {
        opacity: 0.8;
    }

    &--black {
        background-color: $tertiary-text-color;
    }

    &--warning {
        background-color: $primary-error;
    }

    &--blank {
        color: $primary-text-color;
        background-color: $primary-empty-color;
    }
}