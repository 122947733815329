@import 'sass/colors';

.outline-button {
    color: $default-primary;
    fill: $default-primary;
    background-color: transparent;
    border: 0.125rem solid $default-primary;
    padding: 0.375rem 1rem;

    &:hover:not(:disabled) {
        opacity: 0.7;
    }

    circle {
        stroke: $default-primary;
    }

    &--small {
        padding: 0.125rem 1rem;
        font-size: 0.875rem;
        border-radius: 1rem;
    }

    &--warning {
        border-color: $primary-error;
        color: $primary-error;
        fill: $primary-error;

        &:disabled {
            background-color: $primary-blank-color;
            border-color: $primary-error;
            color: $primary-error;
            fill: $primary-error;
        }

        circle {
            stroke: $primary-error;
        }
    }

    &--black {
        background-color: transparent;
        border: 0.0625rem solid $primary-border-input-color;
        color: $tertiary-text-color;
        padding: 0.4375rem 1rem;

        &.outline-button--small {
            padding: 0.1875rem 1rem;
        }

        &:disabled {
            background-color: $primary-blank-color;
            border-color: $tertiary-text-color;
            color: $tertiary-text-color;
            fill: $tertiary-text-color;
        }

        circle {
            stroke: $tertiary-text-color;
        }
    }

    &--blank {
        background-color: transparent;
        border-color: $primary-border-input-color;
        color: $primary-text-color;
    
        &:hover:not(:disabled) {
            background-color: transparent;
            color:  $primary-text-color;
            opacity: 0.8;
        }
    }
}